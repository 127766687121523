/**
 * @prettier
 */

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import loadable from '@loadable/component';
import App from './components/app/App';
import { AppContextProvider } from './context/AppContext';
import { MeContextProvider } from './context/MeContext';
import { PageHeaderContextProvider } from 'liana-ui/components/header/src/PageHeaderContext';
import { MediaContextProvider } from 'liana-ui/components/responsive/src/MediaContext';
import { Auth0ProviderWithNavigate } from './components/auth0/Auth0ProviderWithNavigate';
// Single pages
let HomePage = loadable(() => import('./pages/home/Home'));
let OrganizationsPage = loadable(() => import('./pages/organizations/Organizations'));
let AddOrganizationPage = loadable(() => import('./pages/organizations/AddOrganization'));
let UsersPage = loadable(() => import('./pages/users/Users'));
let Partners = loadable(() => import('./pages/partners/Partners'));
let SolutionsListPage = loadable(() => import('./pages/solutions/Solutions'));
let SolutionInstallPage = loadable(() => import('./pages/solutions/smooth-installation/InstallationIndex'));
let SupportToolsPage = loadable(() => import('./pages/support-tools/SupportTools'));
let CheckUserStatusPage = loadable(() => import('./pages/support-tools/check-user-status/CheckUserStatus'));
let CombineOrganizationsPage = loadable(
	() => import('./pages/support-tools/combine-organizations/CombineOrganizations')
);
let MoveSolutionsPage = loadable(() => import('./pages/support-tools/move-solutions/MoveSolutions'));
let SearchPage = loadable(() => import('./pages/search/Search'));
let PartnerSettingsPage = loadable(() => import('./pages/partners/PartnerSettings'));
let AddPartnerPage = loadable(() => import('./pages/partners/AddPartner'));
const NotFoundPage = loadable(() => import('./pages/not-found/NotFound'));

// Multi-page wrappers
let OrganizationIndex = loadable(() => import('./pages/organizations/organizationWrap/Index'));
let SolutionPage = loadable(() => import('./pages/organizations/solution/Index'));

Promise.config({
	cancellation: true // Enable cancellation
});

const ROUTES = (
	<Router>
		<Auth0ProviderWithNavigate>
			<AppContextProvider>
				<PageHeaderContextProvider>
					<MeContextProvider>
						<MediaContextProvider>
							<App>
								<Routes basename={process.env.basePath || '/'}>
									<Route index element={<Navigate to='home' replace={true} />} />
									<Route path='home' element={<HomePage />} />
									<Route path='organizations'>
										<Route index element={<OrganizationsPage />} />
										<Route path=':orgId/*' element={<OrganizationIndex />} />
										<Route path='add' element={<AddOrganizationPage />} />
									</Route>
									<Route path='partners'>
										<Route index element={<Partners />} />
										<Route path=':resellerId' element={<PartnerSettingsPage />} />
										<Route path='add' element={<AddPartnerPage />} />
									</Route>
									<Route path='users' element={<UsersPage />}></Route>
									<Route path='solutions'>
										<Route index element={<SolutionsListPage />} />
										<Route path='install-solution' element={<SolutionInstallPage />} />
									</Route>
									<Route path='support-tools'>
										<Route index element={<SupportToolsPage />} />
										<Route path='check-user-status' element={<CheckUserStatusPage />} />
										<Route path='combine-organizations' element={<CombineOrganizationsPage />} />
										<Route path='move-solutions' element={<MoveSolutionsPage />} />
									</Route>
									<Route path='search' element={<SearchPage />}></Route>
									<Route path='*' element={<NotFoundPage mainColumn />} />
								</Routes>
							</App>
						</MediaContextProvider>
					</MeContextProvider>
				</PageHeaderContextProvider>
			</AppContextProvider>
		</Auth0ProviderWithNavigate>
	</Router>
);

ReactDOM.render(ROUTES, document.getElementById('app-content'));
